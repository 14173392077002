














import { defineComponent, onBeforeMount, onBeforeUnmount, onActivated, onDeactivated } from '@vue/composition-api'

export default defineComponent({

    name: 'Home',
    components: {},


    setup() {

        // onBeforeMount(() => {
        //     console.log("onActivated")
        //     console.log(document.body.style.backgroundColor)
        //     document.body.style.backgroundColor = "orange";
        //     console.log(document.body.style.backgroundColor)

        // })

        // onBeforeUnmount(() => {
        //     console.log("onDeactivated")
        //     document.body.style.backgroundColor = "red";
        // })


        return {

        }

    }


})
