


























































































import { defineComponent, ref } from '@vue/composition-api'
import { HTTP_LOCKDOWN } from '@/api/http-common'


export default defineComponent({
    name: 'AnswerButton',

    props: {
        pAnswer: {
          type: String,
          required: true,
        },
        pImageFilename: {
            type: String,
            required: false
        },
        pAnswerID: {
          type: Number,
          required: true,
        },
        pAnswerPos: {
          type: Number,
          required: true,
        },
    },

    setup(props, context) {

        var dialog = ref(false)
        const lockdownBaseUrl = ref(HTTP_LOCKDOWN.defaults.baseURL || `http://${window.location.hostname}:8000`)

        function clicked(confirm: boolean) { 
          dialog.value = false
          console.log("[answerButton] cliccato su: ", confirm)
          if(confirm === true) {
            // confermata
            context.emit("confirmed", props.pAnswerID)
          }
        }

        return {
            dialog,
            clicked,
            lockdownBaseUrl
        }
    },

    components: {

    },
})
