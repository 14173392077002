










import { defineComponent, onMounted, ref } from '@vue/composition-api'
import * as state from "@/store/modules/app"


export default defineComponent({

    name: 'Logout',
    components: {},


    setup() {
        const msg = ref("")

        onMounted(() => {
            console.log("mounted Logout view")
            console.log("logged: ", state.getters.isLogged)
            if(state.getters.isLogged) {
                state.actions.logout()
                .then(() => {
                    console.log("SLOGGATO con successo!")
                    msg.value = "Logout avvenuto con successo"
                })
            }else{
                msg.value = "Non sei loggato"
            }
        })

        return {
            msg
        }

    }


})
