

















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'RankingPlayer',

    props: {
        pPosition: {
            type: Number,
            required: true,
        },
        pPlayerID: {
            type: Number,
            required: true,
        },
        pNickname: {
            type: String,
            required: true,
        },
        pAvatar: {
            type: String,
            required: true,
        },
        pScore: {
            // type: Object as PropType<Answer[]>,
            type: Number,
            required: true,
        },
    },

    setup(props, context) {

        return {}
    },

    components: {},
})
