



































































import { Component, Vue } from "vue-property-decorator"
import AlertItem from "@/components/AlertItem.vue"
import { backendAPI } from "@/api/backendAPI"
import * as state from "@/store/modules/app"

@Component({
  components: {
      AlertItem,
  }
})
export default class Codes extends Vue {

    // workaround typescript
    // per usare this.$refs.form.validate()
    $refs!: {
        form: HTMLFormElement
    }

    // alert
    alertShow = false
    alertMessage = "ciao"
    alertStatus = "success"

    mounted() {
        console.log("mounted Login View")
        console.log("logged: ", state.getters.isLogged)
        //console.log("logged: ", store.getters.isLogged)
        // backendAPI.isPlayerAuthenticated(3)
        // .then(data => {
        //     console.log(data)
        // })
    }

    valid = true
    username = ""
    password = ""

    rules = {
        required: (value: string) => !!value || 'Campo obbligatorio',
    }

    redirect() {
        if (this.$route.query && this.$route.query.redirect) {
            // si è arrivati qui ma si voleva andare in un'altra pagina
            // quindi redirigo
            console.log("redirect to previous page")
            this.$router.push(this.$route.query.redirect as any)
        }else{
            // è stata visitata proprio questa pagina.
            // Redirigo alla pagina di Aiuto/Intro
            console.log("redirect to page Help")
            this.$router.push("help")
        }
    }

    send() {
        this.alertShow = false
        const validation = this.$refs.form.validate();
        if(validation) {
            console.log("invia: " + this.username + " " + this.password)
            backendAPI.authentication(this.username, this.password)
            .then(tokens => {
                console.log("ho il token: ", tokens)
                state.actions.login(tokens)
                .then(() => {
                    console.log("loggato. Token storato")
                    // auth.login(token)
                    const playerID = state.getters.userID
                    // const playerID = auth.getUserId()
                    console.log("playerID: ", playerID)

                    if(playerID) {
                        console.log("ho il playerID: ", playerID)
                        // registro il giocatore alla Grande Caccia

                        backendAPI.isPlayerAuthenticated(playerID)
                        .then(isAuthenticated => {
                            console.log("isAuthenticated? ", isAuthenticated)
                            if(isAuthenticated) {
                                this.redirect()
                            }else{
                                backendAPI.registration(playerID)
                                .then(success => {
                                    console.log("giocatore registrato: ", success)
                                    this.redirect()
                                })
                                .catch(error => {
                                    console.error("il giocatore non è riuscito a registrarsi")
                                })
                            }
                        })
                        .catch(error => {
                            console.log("errorone autenticone")
                        })
                    }else{
                        // token non salvato correttamente o token non conforme
                        console.error("non ho il playerID")
                    }
                })


            })
            .catch(e => {
                // TODO: alert?
                console.log("Error: ", e)
                this.alertShow = true
                this.alertMessage = "C'è un problema col server. Riprova!"
                this.alertStatus = "error"
            })
        }
    }

}
