

















































































import { defineComponent, onMounted, ComputedRef, computed, ref } from '@vue/composition-api'
import UnlockedClue from "@/components/unlockedClue.vue"
import * as state from "@/store/modules/app"


export default defineComponent({
  name: 'Questions',
  components: {
    UnlockedClue,
  },

  setup() {

    const unsolvedClues: ComputedRef = computed(() => state.getters.unsolvedClues)
    const solvedClues: ComputedRef = computed(() => state.getters.solvedClues)
    const playerID: ComputedRef = computed(() => state.getters.userID)

    onMounted(() => {
      console.log("mounted Clues")
        if(playerID.value) {
            state.actions.fetchUnsolvedClues(playerID.value)
            .then(() => {
                console.log("unsolved clues fetchati")
                console.log(unsolvedClues.value)
            })
            state.actions.fetchSolvedClues(playerID.value)
            .then(() => {
                console.log("solved clues fetchati")
                console.log(solvedClues.value)
            })
        }
    })

    return {
      unsolvedClues,
      solvedClues,
    }
  }
})
