<template>
    <v-alert v-model="toggle" :type="status" dismissible dense>
        {{ message}}
    </v-alert>
</template>

<script>
    export default {
        props: {
            message: String,
            status: String,
            toggle: Boolean,
        }
    };
</script>