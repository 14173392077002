












import { defineComponent, Ref, ref } from '@vue/composition-api'


export default defineComponent({
    name: 'PopupAlert',
    components: {},

    setup() {

        const overlay: Ref<boolean> = ref(false)
        const alertType: Ref<string> = ref("success")
        const alertIcon: Ref<string> = ref("mdi-emoticon-happy")
        const alertMsg: Ref<string> = ref("")

        function trigger(success: boolean, text: string, duration = 2000) {
            console.log("trigger!!")
            if(success) {
                alertType.value = "success"
                alertMsg.value = text
                alertIcon.value = "mdi-emoticon-happy"
            }else{
                alertType.value = "error"
                alertMsg.value = text
                alertIcon.value = "mdi-emoticon-sad"
            }

            overlay.value = true
            setTimeout(() => {
                overlay.value = false
            }, duration)

        }

        return {
            trigger,
            overlay,
            alertType,
            alertIcon,
            alertMsg,
        }
  }
})
