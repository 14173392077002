import jwt_decode from "jwt-decode"
import { JWT } from '@/types/jwt'


export const isAuthenticated = (): boolean => {
    const token = localStorage.getItem('jwt')
    if (token) {
        return true
    } else {
        return false
    }
}


export const getUserId = (): number | undefined => {
    const token = localStorage.getItem('jwt')
    if(token) {
        const decoded: JWT = jwt_decode(token);
        console.log("decoded:", decoded)
        return decoded.player_id
    }
}


export const login = (token: string): boolean => {
    console.log("login con token: ", token)
    localStorage.setItem("jwt", token)
    return true
}


export const logout = (): boolean => {
    console.log("logout")
    localStorage.removeItem("jwt")
    return true
}
