



















































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'QuestionCard',

    components: {},

    props: {
        pID: {
            type: Number,
            required: true,
        },
        pText: {
            type: String,
            required: true,
        },
        pSolved: {
            type: Boolean,
            required: true
        },
        pPhysical: {
            type: Boolean,
            required: true
        },
        pExpired: {
            type: Boolean,
            required: true
        }
    },

    setup(props, context) {
        return {}
    },

})
