







































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { backendAPI } from "@/api/backendAPI"
import * as auth from "@/utils/auth"
import PopupAlert from "@/components/popupAlert.vue"
import * as state from "@/store/modules/app"


export default defineComponent({

    name: 'Codes',

    components: {
        PopupAlert,
    },

    setup() {

        const code = ref("")
        const popupAlertComp = ref<InstanceType<typeof PopupAlert>>()
        const valid = ref(true)
        const rules = {
            required: (value: string) => !!value || 'Campo obbligatorio',
        }

        onMounted(() => {
            // se si arriva da QRCode
            // c'è "code" nell'url
            const uri = window.location.href.split('?')
            if(uri.length == 2) {
                const codeViaUrl  = new URLSearchParams(uri[1]).get("code")
                if(codeViaUrl) {
                    code.value =codeViaUrl
                    popupAlertComp.value?.trigger(true, `Hai trovato il codice "${code.value}". Prova ad inserirlo!`)
                }
            }
        })

        function send() {
            // const validation = this.$refs.form.validate();
            if(code.value !== "") {
                const playerId = auth.getUserId()
                if (playerId) {
                    console.log("invia: " + code.value)
                    backendAPI.tryToUnlockCode(playerId, code.value)
                    .then(code => {
                        console.log("Code!: ", code)
                        popupAlertComp.value?.trigger(true, "Corretto! Ora hai uno o più nuovi Quiz")
                        // this.$router.push('questions')

                        // fetcho anche le domande sbloccate (dovrebbe essercene una nuova)
                        // così aggiorno anche il numerino di fianco alla voce della pagina nel menu
                        // di navigazione
                        state.actions.fetchUnresolvedQuestions(playerId)
                        // e anche gli indizi sbloccati perchè ce ne sarà uno in meno
                        state.actions.fetchUnsolvedClues(playerId)

                    })
                    .catch(errorMsg => {
                        //console.log("ma che diamine" + errorMsg)
                        //popupAlertComp.value?.trigger(false, "Codice sbagliato. Riprova!")
                        popupAlertComp.value?.trigger(false, errorMsg)
                    })
                }
            }
        }

        return {
            popupAlertComp,
            code,
            valid,
            rules,
            send,
        }
    }


})
