





















import { Component, Vue, Prop } from "vue-property-decorator"
import { Code } from "@/types/game"


@Component({
  components: {

  }
})
export default class UnlockedCodes extends Vue {

    @Prop({ default: []}) codes!: Code[]

}
