










































import { defineComponent, onMounted, PropType, ref, computed } from '@vue/composition-api'
import { Answer } from "@/types/game"
import AnswerButton from "@/components/answerButton.vue"
import { HTTP_LOCKDOWN } from '@/api/http-common'


export default defineComponent({
    name: 'QuestionCard',

    props: {
        pLabel: {
            type: String,
            required: true,
        },
        pText: {
            type: String,
            required: true,
        },
        pImageFilename: {
            type: String,
            required: false
        },
        pQuestionID: {
            type: Number,
            required: true,
        },
        pAnswers: {
            // type: Object as PropType<Answer[]>,
            type: Array,
            required: true,
        },
    },

    setup(props, context) {

        const lockdownBaseUrl = ref(HTTP_LOCKDOWN.defaults.baseURL || `http://${window.location.hostname}:8000`)

        onMounted(() => {
            console.log("mounted question")
        })

        

        function sendAnswer(answerID: number) { 
            // emetto l'evento alla pagina che manderà al backend
            context.emit("answered", props.pQuestionID, answerID)
        }

        return {
            sendAnswer,
            lockdownBaseUrl,
        }
    },

    components: {
        AnswerButton,
    },
})
