































import { defineComponent, onMounted, ComputedRef, computed } from '@vue/composition-api'
import * as state from "@/store/modules/app"
import RankingPlayer from "@/components/RankingPlayer.vue"


export default defineComponent({
  name: 'Questions',
  components: {
    RankingPlayer,
  },

  setup() {

    const ranking: ComputedRef = computed(() => state.getters.ranking)

    onMounted(() => {
      console.log("mounted Ranking View")

        state.actions.fetchRanking()
        .then(() => {
            console.log("ranking fetchata")
        })


    })


    return {
      ranking,
    }
  }
})
