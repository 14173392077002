











































import { defineComponent, onMounted, ComputedRef, computed, ref } from '@vue/composition-api'
import UnlockedCodes from "@/components/UnlockedCodes.vue"
import * as state from "@/store/modules/app"

export default defineComponent({
    name: 'Questions',
    components: {
        UnlockedCodes,
    },

    setup() {

        const playerID: ComputedRef = computed(() => state.getters.userID)
        // const unlockedCluesNumber: ComputedRef = computed(() => state.getters.unlockedCluesNumber)
        const unsolvedCluesNumber: ComputedRef = computed(() => state.getters.unsolvedCluesNumber)
        const solvedCluesNumber: ComputedRef = computed(() => state.getters.solvedCluesNumber)

        const unlockedCluesNumber: ComputedRef = computed(() => unsolvedCluesNumber.value + solvedCluesNumber.value)

        const score: ComputedRef = computed(() => state.getters.score)
        const rank: ComputedRef = computed(() => state.getters.rank)

        onMounted(() => {
            console.log("mounted Dashboard View")
            if(playerID.value) {
                // fetch unlocked clue
                // state.actions.fetchUnlockedClues(playerID.value)
                // .then(() => {
                //     console.log("unlocked clues fetchati")
                // })

                // fetch unsolved clue
                state.actions.fetchUnsolvedClues(playerID.value)
                .then(() => {
                    console.log("unsolved clues fetchati")
                })

                // fetch solved clue
                state.actions.fetchSolvedClues(playerID.value)
                .then(() => {
                    console.log("solved clues fetchati")
                })

                // fetch player score
                state.actions.fetchPlayerScore(playerID.value)
                .then(() => {
                    console.log("player score fetchato")
                })

                // fetch player rank
                state.actions.fetchPlayerRank(playerID.value)
                .then(() => {
                    console.log("player rank fetchato")
                })
            }
        })

        return {
            playerID,
            unlockedCluesNumber,
            unsolvedCluesNumber,
            solvedCluesNumber,
            score,
            rank,
        }
    },

})
// @Component({
//   components: {
//       UnlockedCodes,
//   }
// })
// export default class Codes extends Vue {


//     codes: Code[] = []

//     mounted() {
//         backendAPI.getUnlockedCodes(1)
//         .then(codes => {
//             console.log("unlocked codes!: ", codes)
//             this.codes = codes
//         })
//         .catch(errorMsg => {
//             console.log("error unlocked codes!: ", errorMsg)
//         })
//     }

// }
