



















































import { defineComponent, onMounted, ref, Ref } from '@vue/composition-api'
import { backendAPI } from "@/api/backendAPI"
import { GameSupportLink } from '@/types/game'


export default defineComponent({

    name: 'Help',
    components: {},

    setup() {

        const startText = ref("")
        const endText = ref("")
        const supportText = ref("")
        const links: Ref<GameSupportLink[]> = ref([])

        onMounted(() => {
            console.log("mounted Help view")

            backendAPI.getGameSupportData()
            .then(data => {
                startText.value = data.start_text
                endText.value = data.end_text
                supportText.value = data.support_text
                links.value = data.links
            })
            .catch(error => {
                console.log(error)
            })

        })

        return {
            startText,
            endText,
            supportText,
            links,
        }

    }

})
