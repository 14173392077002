






















































import { defineComponent, onMounted, ComputedRef, computed, ref } from '@vue/composition-api'
import * as state from "@/store/modules/app"
import QuestionCard from "@/components/questionCard.vue"
import SolvedQuestionCard from "@/components/solvedQuestionCard.vue"
import PopupAlert from "@/components/popupAlert.vue"
import { backendAPI } from "@/api/backendAPI"


export default defineComponent({
  name: 'Questions',
  components: {
    QuestionCard,
    PopupAlert,
    SolvedQuestionCard,
  },

  setup() {

    const unresolvedQuestions: ComputedRef = computed(() => state.getters.unresolvedQuestions)
    const solvedQuestions: ComputedRef = computed(() => state.getters.solvedQuestions)
    const popupAlertComp = ref<InstanceType<typeof PopupAlert>>()
    const playerID: ComputedRef = computed(() => state.getters.userID)

    onMounted(() => {
      console.log("mounted Questions View")
        if(playerID.value) {
            state.actions.fetchUnresolvedQuestions(playerID.value)
            .then(() => {
                console.log("unresolved questions fetchate")
                console.log(unresolvedQuestions.value)
            })
            state.actions.fetchSolvedQuestions(playerID.value)
            .then(() => {
                console.log("solved questions fetchate")
                console.log(solvedQuestions.value)
            })
        }
    })

    function answer(questionID: number, answerID: number) {
      console.log(`risposta alla domanda ${questionID} scelta e confermata: ${answerID}`)
      if(playerID.value) {
        backendAPI.playerTryToResolveQuestion(playerID.value, questionID, answerID)
        .then((msg) => {
            // risposta giusta
            console.log("risposta giusta: ", msg)

            // trigger overlay alert
            popupAlertComp.value?.trigger(true, "Risposta esatta!")
        })
        .catch((errorMsg) => {
            // risposta sbagliata
            console.log("risposta sbagliata: ", errorMsg)

            // trigger overlay alert
            popupAlertComp.value?.trigger(false, "Risposta sbagliata!")
        })
        .finally(() => {
            // re-fetch questions
            state.actions.fetchUnresolvedQuestions(playerID.value)
            state.actions.fetchSolvedQuestions(playerID.value)
            // fetcho anche gli indizi sbloccati (dovrebbe essercene uno nuovo)
            // così aggiorno anche il numerino di fianco alla voce della pagina nel menu
            // di navigazione
            state.actions.fetchUnsolvedClues(playerID.value)
            // e anche le domande (ce n'è una in meno)
            state.actions.fetchUnresolvedQuestions(playerID.value)
        })
      }
    }

    return {
      unresolvedQuestions,
      solvedQuestions,
      answer,
      popupAlertComp,
    }
  }
})
